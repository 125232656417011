'use client';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.base};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: ${props => props.width};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const Headline = styled.h1`
  font-size: 26px;
  font-weight: 400;
  max-width: 512px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: ${({ theme }) => theme.spacing(0, 6, 2, 6)};
`;

export const Subheadline = styled.h2`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: ${({ theme }) => theme.spacing(0, 6)};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;
