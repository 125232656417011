import chickenFreshPackShot from '../images/product-photos/pack-shots/Fresh-packshot-chicken.jpg';
import beefFreshPackShot from '../images/product-photos/pack-shots/Fresh-packshot-beef.jpg';
import lambFreshPackShot from '../images/product-photos/pack-shots/Fresh-packshot-lamb.jpg';
import turkeyFreshPackShot from '../images/product-photos/pack-shots/Fresh-packshot-turkey.jpg';
import dryBeefImage from '../images/product-photos/pack-shots/Dry-packshot-beef.jpg';
import dryChickenImage from '../images/product-photos/pack-shots/Dry-packshot-chicken.jpg';
import JerkyTreatBeefSweetPotatoImage from '../images/our-food/addons/JerkyTreats-BeefSweetPotato.jpg';
import JerkyTreatChickenAppleImage from '../images/our-food/addons/JerkyTreats-ChickenApple.jpg';
import SweetPotatoTreatImage from '../images/our-food/addons/StripTreats-SweetPotato.jpg';
import ZoomiesImage from '../images/our-food/addons/Supplements-Zoomies.png';
import ChillPillsImage from '../images/our-food/addons/Supplements-ChillPills.png';
import BellyRubsImage from '../images/our-food/addons/Supplements-BellyRubs.png';
import {
  supplementsDropdownsAndValues,
  treatsDropdownsAndValues,
} from '../testing/constants';

export const ACCOUNT_URL = '/api/set_account/';
export const ERROR_MESSAGE = `Changes failed, please refresh the page and try again.`;
export const PAYMENT_ERROR_MESSAGE =
  'There was an error processing your credit card.';
export const PAYMENT_ERROR_MESSAGE_INSUFFICIENT_FUNDS =
  'Your card has insufficient funds.';
export const TOAST_SERVER_MESSAGE =
  'Hmm. Something went wrong. Please try submitting again.';
export const TOAST_CLIENT_INCORRECT_PASSWORD_MESSAGE =
  'Current password is incorrect. Need to reset it? Click the link below.';
export const QUICK = 'quick';
export const CUSTOM_DATE = 'custom';
export const CALENDAR = 'calendar';
export const GF_PLAN_CHANGE_WARNING =
  "ⓘ We've updated our pricing. If you save any changes, it may result in a change to your price that you can't undo.";
export const GF_PLAN_CHANGE_WARNING_TESTING =
  "If you save any changes, it may result in a change to your price that you can't undo.";
export const CRO_PET_BIRTH_DATE = '2000-03-14';
export const MEAL_FREQUENCY_MAP = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
};

export const MEAL_PLAN_PRODUCT_TYPES = {
  fresh: 'FR',
  dry: 'DY',
  mixed: 'MX',
};

export const MEAL_PLAN_PRODUCT_TYPES_LIST = Object.values(
  MEAL_PLAN_PRODUCT_TYPES,
);

export const FRESH_RECIPES = [
  {
    title: 'Beef',
    label: 'Add Beef',
    priceSymbol: 'low',
    value: 'beef',
    benefit: 'Nutrient-rich recipe',
    description:
      'Beef, sweet potatoes, and peas pack a hearty punch, providing dogs of all ages with all the essential nutrients they need to hit the ground running.',
    showMoreDetails: true,
    unavailableText:
      'Based on the allergies {{pupName}} has, this recipe is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
    img: {
      src: beefFreshPackShot.src,
      alt: 'beef recipe',
    },
  },
  {
    title: 'Chicken',
    label: 'Add Chicken',
    priceSymbol: 'mid',
    value: 'chicken',
    benefit: 'Great for sensitive stomachs',
    description:
      'Chicken, carrots, and rice are a gentle, yet satisfying combination for dogs with sensitive stomachs, or picky palates.',
    showMoreDetails: true,
    unavailableText:
      'Based on the allergies {{pupName}} has, this recipe is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
    img: {
      src: chickenFreshPackShot.src,
      alt: 'chicken recipe',
    },
  },
  {
    title: 'Lamb',
    label: 'Add Lamb',
    priceSymbol: 'high',
    value: 'lamb',
    benefit: 'Good for allergy prone dogs',
    description:
      'Real lamb, cranberries, and butternut squash keep mealtime fresh and exciting and give dogs the fuel they need to stay happy and healthy in the long run.',
    showMoreDetails: true,
    unavailableText:
      'Based on the allergies {{pupName}} has, this recipe is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
    img: {
      src: lambFreshPackShot.src,
      alt: 'lamb recipe',
    },
  },
  {
    title: 'Turkey',
    label: 'Add Turkey',
    priceSymbol: 'mid',
    value: 'turkey',
    benefit: 'Features lean protein',
    description:
      'Turkey teams up with superfoods like blueberries and kale to provide dogs with a healthy helping of essential nutrients in every bite.',
    showMoreDetails: true,
    unavailableText:
      'Our turkey recipe is currently unavailable due to a national turkey shortage and supply chain disruption. In the meantime, enjoy our other healthy recipes.',
    img: {
      src: turkeyFreshPackShot.src,
      alt: 'turkey recipe',
    },
  },
];

export const DRY_RECIPES = [
  {
    title: 'Beef',
    label: 'Add Beef',
    priceSymbol: 'low',
    value: 'beef',
    benefit: 'Nutrient-rich recipe',
    description: `Hearty beef and sweet potatoes, plus nutrient-rich liver and oats, make for a well-rounded source of vitamins and minerals.`,
    showMoreDetails: true,
    unavailableText:
      'Based on the allergies {{pupName}} has, this recipe is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
    img: {
      src: dryBeefImage.src,
      alt: 'beef recipe',
    },
  },
  {
    title: 'Chicken',
    label: 'Add Chicken',
    priceSymbol: 'low',
    value: 'chicken',
    benefit: 'Great for sensitive stomachs',
    description: `
      With chicken as the first ingredient, plus chickpeas, eggs, and veggies,
      this recipe packs antioxidants, protein, and fiber into one hearty meal.
      `,
    showMoreDetails: true,
    unavailableText:
      'Based on the allergies {{pupName}} has, this recipe is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
    img: {
      src: dryChickenImage.src,
      alt: 'chicken recipe',
    },
  },
];

export const ADDON_PRODUCT_TYPES = {
  treats: 'TR',
  supplements: 'SP',
};

export const ADDON_SKUS = {
  beefjerky: 'JERKYSTRIPS-BE',
  chickenjerky: 'JERKYSTRIPS-CH',
  turkeyjerky: 'JERKYSTRIPS-TU',
  zoomies: 'ZOOMIES01',
  bellyrubs: 'BELLYRUBS01',
  chillpills: 'CHILLPILLS01',
  sweetpotatoes: 'SWEETPOTATOSLICES1',
  dentalchewxs: 'SUPP-DENT-XS',
  dentalchewsm: 'SUPP-DENT-SM',
  dentalchewmd: 'SUPP-DENT-MD',
  dentalchewlg: 'SUPP-DENT-LG',
  trainingtreatspb: 'TREAT-PBTRAINING-08',
  trainingtreatsparm: 'TREAT-PARMTRAINING-08',
  sweetpotatocrunch: 'TREAT-SWEETPOTATOCRNCH-08',
  applecrunchtreat: 'TREAT-APPLECRNCH-08',
  skinsupplement: 'SUPP-SKIN',
  immunesupplement: 'SUPP-IMMUNE',
  pbicecream: 'FR-ICECREAM-PBB',
  salmontreat: 'JERKYSTRIPS-SA',
};

export const MEAL_PLAN_TYPES = {
  DY: 'dry',
  FR: 'fresh',
  MX: 'mixed',
};

export const ADDONS = [
  {
    sku: ADDON_SKUS.chickenjerky,
    productName: 'Chicken and Apple Jerky',
    title: 'Chicken and Apple Jerky',
    description: 'Simple and delicious, your dog will crave these treats.',
    productType: 'TR',
    subtitle: 'Jerky Treats',
    size: '1 bag (5oz.)',
    pricingInfo: {
      price: '{{addonPrice}}',
      discountedPrice: '{{addonDiscount}}',
    },
    image: {
      src: JerkyTreatChickenAppleImage.src,
      alt: 'Chicken And Apple Recipe Jerky Image',
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.beefjerky,
    productName: 'Beef and Sweet Potato Jerky',
    title: 'Beef and Sweet Potato Jerky',
    description:
      'Clean, simple treats to reward the best and bravest behavior.',
    productType: 'TR',
    subtitle: 'Jerky Treats',
    size: '1 bag (5oz.)',
    pricingInfo: {
      price: '{{addonPrice}}',
      discountedPrice: '{{addonDiscount}}',
    },
    image: {
      src: JerkyTreatBeefSweetPotatoImage.src,
      alt: 'Beef and Sweet Potato Recipe Jerky Image',
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.sweetpotatoes,
    productName: 'Sweet Potato Slices',
    title: 'Sweet Potato Slices',
    description:
      'One ingredient treats keep snack time simple, yet satisfying.',
    productType: 'TR',
    subtitle: 'Slice Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$27.50', discountedPrice: '$22.00' },
    image: {
      src: SweetPotatoTreatImage.src,
      alt: 'Sweet Potato Slices Image',
    },
    dropdowns: treatsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.zoomies,
    productName: 'Zoomies',
    title: 'Zoomies',
    description:
      'For healthy hips and joints, this supplement will add some pep to their step.',
    productType: 'SP',
    subtitle: 'Hip and Joint Formula',
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '{{addonPrice}}',
      discountedPrice: '{{addonDiscount}}',
    },
    image: {
      src: ZoomiesImage.src,
      alt: 'Hip and Joint Supplement Image',
    },
    dropdowns: supplementsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.chillpills,
    productName: 'Chill Pills',
    title: 'Chill Pills',
    description:
      'Get your pup to kick back and relax with these mellowing, all-natural supplements.',
    productType: 'SP',
    subtitle: 'Calming Formula',
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '{{addonPrice}}',
      discountedPrice: '{{addonDiscount}}',
    },
    image: {
      src: ChillPillsImage.src,
      alt: 'Chill Pills Supplement Image',
    },
    dropdowns: supplementsDropdownsAndValues.dropdowns,
  },
  {
    sku: ADDON_SKUS.bellyrubs,
    productName: 'Belly Rubs',
    title: 'Belly Rubs',
    description:
      'Say goodbye to the tummy troubles with these delicious treats.',
    productType: 'SP',
    subtitle: 'Probiotic Formula',
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '{{addonPrice}}',
      discountedPrice: '{{addonDiscount}}',
    },
    image: {
      src: BellyRubsImage.src,
      alt: 'Belly Rubs Supplement Image',
    },
    dropdowns: supplementsDropdownsAndValues.dropdowns,
  },
];

export const DENTAL_CHEWS_SKUS = [
  'SUPP-DENT-XS',
  'SUPP-DENT-SM',
  'SUPP-DENT-MD',
  'SUPP-DENT-LG',
];
