'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import dogWaggingTail from '../../images/ollie-tail-wag.svg';
import dogLookingInBox from '../../images/dog-looking-in-box2.png';
import { useReporter } from '../../reporter';
import { Container, ImageContainer, Headline, Subheadline } from './styles.js';
import { ruffTimeView } from './events';

export const LoadingView = ({ variant = 'default', onClick }) => {
  const variantMap = {
    default: {
      image: {
        src: dogWaggingTail.src,
        alt: 'Dog wagging tail',
        width: dogWaggingTail.width,
        height: dogWaggingTail.height,
      },
      headline: 'Loading...',
    },
    failed: {
      image: {
        src: dogLookingInBox.src,
        alt: 'Dog looking in box',
        width: dogLookingInBox.width,
        height: dogLookingInBox.height,
      },
      headline: "Uh oh! We're havin' a ruff time...",
      subHeadline: "Sit tight, we'll figure this out as soon as we can!",
      cta: 'Try again',
    },
  };
  const reporter = useReporter();

  useEffect(() => {
    if (variant === 'failed') {
      reporter.tag(ruffTimeView());
    }
  }, [reporter, variant]);
  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage
          src={variantMap[variant].image.src}
          alt={variantMap[variant].image.alt}
          width={variantMap[variant].image.width}
          height={variantMap[variant].image.height}
        />
      </ImageContainer>
      <Headline>{variantMap[variant].headline}</Headline>
      {variantMap[variant].subHeadline && (
        <Subheadline>{variantMap[variant].subHeadline}</Subheadline>
      )}
      {variantMap[variant].cta && (
        <Button onClick={onClick}>{variantMap[variant].cta}</Button>
      )}
    </Container>
  );
};

LoadingView.displayName = 'LoadingView';

LoadingView.propTypes = {
  variant: PropTypes.oneOf(['default', 'failed']),
  onClick: PropTypes.func,
};
